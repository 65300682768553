exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx?export=default" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-subscribers-tsx": () => import("./../../../src/pages/subscribers.tsx?export=default" /* webpackChunkName: "component---src-pages-subscribers-tsx" */),
  "component---src-pages-test-2-tsx": () => import("./../../../src/pages/test2.tsx?export=default" /* webpackChunkName: "component---src-pages-test-2-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx?export=default" /* webpackChunkName: "component---src-pages-test-tsx" */)
}



exports.head = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx?export=head" /* webpackChunkName: "component---src-pages-index-tsxhead" */),
  "component---src-pages-subscribers-tsx": () => import("./../../../src/pages/subscribers.tsx?export=head" /* webpackChunkName: "component---src-pages-subscribers-tsxhead" */),
  "component---src-pages-test-2-tsx": () => import("./../../../src/pages/test2.tsx?export=head" /* webpackChunkName: "component---src-pages-test-2-tsxhead" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx?export=head" /* webpackChunkName: "component---src-pages-test-tsxhead" */)
}

